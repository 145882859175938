<template>
<div class="wrapper">
    <div class="row" v-for="column in images">
        <div class="column" v-for="src in column">
            <img :src="src" alt>
        </div>
    </div>
</div>
</template>

<script>
export default {
  props: ["images"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";
.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  flex: 1 0 15%;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include responsive(phone) {
    flex: 1 0 10%;
  }
}

.column img {
  margin-top: 8px;
  max-width: 100%;
}

.wrapper {
    padding-top: 60px;
    padding-bottom: 120px;
    @include responsive(phone) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
</style>
