<template>
<div class='outer-wrapper'>
    <img :src="imgSrc" alt="PartnerLogo"/>
    <div class="row-wrapper">
        <div class="data-wrapper"> 
            <p class="value"> {{engagements}}</p>
            <p class="sub-text"> Certifications </p>
        </div>
        <div class="data-wrapper"> 
            <p class="value"> {{consultants}}</p>
            <p class="sub-text"> Completed Projects  </p>
        </div>
        <div class="data-wrapper"> 
            <p class="value"> {{customers}}</p>
            <p class="sub-text"> Unique Customers </p>
        </div>
    </div>
</div>

</template>

<script>
export default {
  props: ["imgSrc", "engagements", "consultants", "customers"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

img {
    @include margin(2 0 2 0)
}
.data-wrapper {
    border-left: 5px solid #FFFFFF;
    flex : 0 4 33.333%;
    padding-left: 20px;
    @include responsive(tablet) {
        padding-left: 6px;
        border-left: 4px solid #FFFFFF;
    }
}
.value {
    @include color(takeda-sorairo-blue);
    font-size: 32px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 0px;
    @include responsive(tablet) {
        font-size: 24px;
    }
}
.sub-text {
    @include color(takeda-dark-gray);
    font-size: 14px;
    font-weight: 300;
    margin-top: 0px;
    @include responsive(tablet) {
        font-size: 12px;
    }
}
.outer-wrapper {
    width: 50%;
    display: inline-block;
    margin-bottom: 25px;
}
.row-wrapper {
    display: flex;
    @include responsive(phone) {
        display: inline-block;
    }
}
</style>
