<template>
	<div class="container">
		<h1>{{ title }}</h1>
		<div class="flex-container">
			<div class="img-container">
				<img :src="imgSrc" alt="Avi Picture" />
			</div>
			<div class="content-container">
				<p class='content-text'>{{ content }}</p>
				<div class='person-desc-container'>
					<p class='person-desc-bold'>{{ name }}&nbsp;</p> 
					<p class='person-desc-light'>{{ ' | ' +  titleSlalom}}</p>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	props: [
		'title',
		'content',
		'imgSrc',
		'name',
		'titleSlalom',
		'companyName',
	],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.container {
	@include padding(5 10 5 10);
	@include responsive(phone) {
		@include padding(5 0 5 0)
    }
    @include responsive(tablet) {
		@include padding(5 5 5 5)
    }
}

.content-container {
	border-left: 5px solid #edf2f4;
    @include margin-left(5);
    @include padding-left(5);
	@include responsive(phone) {
        font-size: 1.8vh;
		@include padding-left(3);
		@include margin-left(3);
    }
}



.content-container {
	font-weight: 400;
}



.person-desc-container {
    display: flex;
    flex-direction: row;
	align-content: center;
}




</style>