<template>
  <Alert :isActive="isError" v-if="isError">{{ errorMessage }}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
    <Navigation></Navigation>
    <div class="theme-container">
      <PageTitle
        :title="content['page-title']"
        :imgSrc="images['header.svg']"
        bgColor="#f78c85"
        :showSlalomLogo="true"
      ></PageTitle>
      <div class="sectionContainerTitle">
        <h1 class="main-title" v-html="sectionOne.title">
        </h1>
        <div class="descriptionCards">
          <div class="flex-row">
            <template v-for="card in sectionOne.cards">
              <div class="flex-4 descriptionCard">
                <SingleCard :title="card.contentTitle" :subtitleBold="card.subtitleBold" :contentText="card.description" />
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="sectionContainer" style="padding-top: 0">
        <h1 class="blue-subtitle-title">
          {{ content["section-1-subsection-2-title"] }}
        </h1>
        <div class="atAGlanceCards">
          <div class="flex-row justify-content-center">
            <template v-for="card in atAGlanceCards">
              <div class="flex-3 atAGlanceCard">
                <SingleCard :subtitle="card.title" :contentText="card.subtitle"/>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div>
        <div class="sectionContainerGuiding">
          <h1 class="main-title">
            {{ content["section-1-subsection-3-title"] }}
          </h1>
          <p class="subtitle">
            {{ content["section-1-subsection-3-subtitle"] }}
          </p>
          <p>{{ content["section-1-subsection-3-description"] }}</p>
          <div class="guidingValuesCards">
            <div class="flex-row justify-content-center align-items-stretch">
              <template v-for="card in guidingValuesCards">
                <div class="flex-auto guidingValuesCard">
                  <SingleCard :subtitleBold="card.title" :contentText="card.paragraph" :imgSrc=[card.imgSrc] :listContent="card.listContent"/>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="sectionContainer sorairo-blue-container">
          <h1 class="main-title-white pad-12">
            {{ content["section-2-title"] }}
          </h1>
          <h1 class="white-subtitle-title">
            {{ content["section-2-subtitle"] }}
          </h1>
          <div class="servicesCards">
            <div class="flex-row justify-content-center align-items-stretch">
              <template v-for="card in servicesCards">
                <div class="flex-auto servicesCard">
                  <SingleCard :subtitleBold="card.title" :contentText="card.paragraph" :listContent="card.listContent"/>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="sectionContainer grey-container">
          <h1 class="main-title">{{ content["section-3-title"] }}</h1>
          <p class="subtitle">{{ content["section-3-subtitle"] }}</p>
          <p style="margin-bottom: 50px">{{ content["section-3-description"] }}</p>
          <h1 class="blue-subtitle">
            {{ content["section-3-subsection-1-title"] }}
          </h1>
          <div class="lifeSciencesCards">
            <div class="flex-row">
              <template v-for="card in lifeSciencesCards">
                <div class="flex-3 lifeSciencesCard">
                  <SingleCard :subtitle="card.title" :contentText="card.subtitle"/>
                </div>
              </template>
            </div>
          </div>
          <h1 class="blue-subtitle" v-html="lifeScienceCustomers.title"/>
          <div class="lifeScienceCustomersCards">
            <div class="flex-row">
              <template v-for="card in lifeScienceCustomers.cards">
                <div class="flex-3 lifeScienceCustomersCard">
                  <SingleCard :subtitle="card.title" :contentText="card.subtitle"/>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="sectionContainer">
          <h1 class="main-title" style="font-size:32px; margin-bottom: 40px">{{ content["section-4-subsection-1-title"] }}</h1>
           <div class="expertiseCards">
            <div class="flex-row justify-content-center align-items-stretch">
              <template v-for="card in expertiseCards">
                <div class="flex-auto expertiseCard">
                  <SingleCard :subtitleBold="card.title" :contentText="card.paragraph" :listContent="card.listContent"/>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="sectionContainer">
          <h1 style="font-size: 32px" v-html="allClients.title"/>
        </div>
        <div class="card-container">
          <Card
          :title="content['section-4-subsection-3-title']"
          :content="
            content['section-4-subsection-3-description']
          "
          :imgSrc="`${images['section-4-subsection-3-photo.png']}`"
          :flipImage="true"
          :buttonLink="{link:content['section-4-subsection-3-link-1-value'],text:content['section-4-subsection-3-link-1-title']}"
        />
        </div>
        <div class="sectionContainer grey-container">
          <h1 class="main-title" v-html="partnerships.title"></h1>
          <p class="subtitle">{{ content["section-5-subtitle"] }}</p>
          <p>{{ content["section-5-description"] }}</p>
          <template v-for="partnership in partnerships.items">
            <PartnershipInfo
              :imgSrc="partnership.imgSrc"
              :engagements="partnership.engagements"
              :customers="partnership.customers"
              :consultants="partnership.consultants"
            />
          </template>
        <div class="partner-award-wrapper"> 
            <div class="partner-award-column">
              <p><span style="font-weight: 600">{{content['section-5-subsection-7-award-1-title']}}</span> | {{content['section-5-subsection-7-award-1-value']}} </p>
              <p><span style="font-weight: 600">{{content['section-5-subsection-7-award-2-title']}}</span> | {{content['section-5-subsection-7-award-2-value']}} </p>
              <p><span style="font-weight: 600">{{content['section-5-subsection-7-award-3-title']}}</span> | {{content['section-5-subsection-7-award-3-value']}} </p>
              <p><span style="font-weight: 600">{{content['section-5-subsection-7-award-4-title']}}</span> | {{content['section-5-subsection-7-award-4-value']}} </p>
            </div>
            <div class="partner-award-column">
              <p><span style="font-weight: 600">{{content['section-5-subsection-7-award-5-title']}}</span> | {{content['section-5-subsection-7-award-5-value']}} </p>
              <p><span style="font-weight: 600">{{content['section-5-subsection-7-award-6-title']}}</span> | {{content['section-5-subsection-7-award-6-value']}} </p>
              <p><span style="font-weight: 600">{{content['section-5-subsection-7-award-7-title']}}</span> | {{content['section-5-subsection-7-award-7-value']}} </p>
            </div>
          </div>
        </div>
        <div class="sectionContainer">
          <h1 v-html="recognition.title"></h1>
          <div class="recognition-wrapper"> 
            <div class="recognition-column">
              <img :src="images['recognition-photo-1.png']" class="recognition-img" />
              <img :src="images['recognition-photo1-4.png']" class="recognition-img" />
            </div>
            <div class="recognition-column">
              <img :src="images['recognition-photo1-2.png']" class="recognition-img" />
              <img :src="images['recognition-photo1-5.png']" class="recognition-img" />
            </div>
            <div class="recognition-column">
              <img :src="images['recognition-photo1-3.png']" class="recognition-img" />
              <img :src="images['recognition-photo1-6.png']" class="recognition-img" />
            </div>
          </div>
        </div>
        <div class="sectionContainer sorairo-dark-blue-container">
          <div class="feedback-section">
            <h1 style="margin-bottom: 20px">
              {{ content["section-7-title"] }}
            </h1>
            <div class="feedback-contact-text">
              <p class="decorated-white-text">
                {{ content["section-7-name"]}}
                <br>{{content["section-7-email"] }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BottomNavigator :order="6" />
  </Loading>
</template>

<script>
import Alert from "@/components/Alert.vue";
import BottomNavigator from "@/components/BottomNavigator.vue";
import Card from "@/components/Card.vue"
import CardGrid from "@/components/CardGrid.vue";
import GuidingValueCard from "@/components/GuidingValueCard.vue";
import ImageGrid from "@/components/ImageGrid.vue"
import Loading from "@/components/Loading.vue";
import Navigation from "@/components/Navigation.vue";
import PageTitle from "@/components/PageTitle.vue";
import PartnershipInfo from "@/components/PartnershipInfo.vue";
import Signature from "@/components/Signature.vue";
import SingleCard from "@/components/SingleCard.vue";
import api from "@/services/api.js";

export default {
  data: () => ({
    content: {},
    images: {},
    isLoading: false,
    isError: false,
    errorMessage: "",
    sectionOne: {
      title:"",
      cards:[],
    },
    atAGlanceCards:[],
    guidingValueCards: [],
    servicesCards:[],
    lifeSciencesCards: [],
    lifeScienceCustomers: {
      title:"",
      cards:[],
    },
    allClients: {
      title: "",
    },
    expertiseCards:[],
    partnerships: {
      title: "",
      items:[],
    },
    recognition:{
      title: "",
    },
    clientLogos: []
  }),
  methods: {
    async getData() {
      this.isError = false;
      this.isLoading = true;
      try {
        const { data } = await api.getContent("slalom");
        this.content = data.content;
        this.images = data.images;
        this.sectionOne.title = data.content["section-1-subsection-1-title"].replace(/<altColor>/g, '<span class="altColor">').replace(/<\/altColor>/g, '<\/span>')
        this.sectionOne.cards = [
          {
            contentTitle: data.content['section-1-subsection-1-card-1-title'],
            description: data.content['section-1-subsection-1-card-1-description'],
            subtitleBold: data.content['section-1-subsection-1-card-1-subtitle'],
          },
          {
            contentTitle: data.content['section-1-subsection-1-card-2-title'],
            description: data.content['section-1-subsection-1-card-2-description'],
            subtitleBold: data.content['section-1-subsection-1-card-2-subtitle'],
          },
          {
            contentTitle: data.content['section-1-subsection-1-card-3-title'],
            description: data.content['section-1-subsection-1-card-3-description'],
            subtitleBold: data.content['section-1-subsection-1-card-3-subtitle'],
          },
        ]
        this.atAGlanceCards = [
          {
            title: data.content['section-1-subsection-2-card-1-value'],
            subtitle: data.content['section-1-subsection-2-card-1-description'],
          },
          {
            title: data.content['section-1-subsection-2-card-2-value'],
            subtitle: data.content['section-1-subsection-2-card-2-description'],
          },
          {
            title: data.content['section-1-subsection-2-card-3-value'],
            subtitle: data.content['section-1-subsection-2-card-3-description'],
          },
          {
            title: data.content['section-1-subsection-2-card-4-value'],
            subtitle: data.content['section-1-subsection-2-card-4-description'],
          },
          {
            title: data.content['section-1-subsection-2-card-5-value'],
            subtitle: data.content['section-1-subsection-2-card-5-description'],
          },
          {
            title: data.content['section-1-subsection-2-card-6-value'],
            subtitle: data.content['section-1-subsection-2-card-6-description'],
          },
        ];

        this.guidingValuesCards = [
          {
            imgSrc: data.images['section-1-subsection-2-value-1-icon.svg'],
            title: data.content['section-1-subsection-3-card-1-title'],
            paragraph:
              data.content['section-1-subsection-3-card-1-description'],
          },
          {
            imgSrc: data.images['section-1-subsection-2-value-2-icon.svg'],
            title: data.content['section-1-subsection-3-card-2-title'],
            paragraph:
              data.content['section-1-subsection-3-card-2-description'],
          },
          {
            imgSrc: data.images['section-1-subsection-2-value-3-icon.svg'],
            title: data.content['section-1-subsection-3-card-3-title'],
            paragraph:
              data.content['section-1-subsection-3-card-3-description'],
          },
          {
            imgSrc: data.images['section-1-subsection-2-value-4-icon.svg'],
            title: data.content['section-1-subsection-3-card-4-title'],
            paragraph:
              data.content['section-1-subsection-3-card-4-description'],
          },
          {
            imgSrc: data.images['section-1-subsection-2-value-5-icon1.svg'],
            title: data.content['section-1-subsection-3-card-5-title'],
            paragraph:
              data.content['section-1-subsection-3-card-5-description'],
          },
          {
            imgSrc: data.images['section-1-subsection-2-value-6-icon.svg'],
            title: data.content['section-1-subsection-3-card-6-title'],
            paragraph:
              data.content['section-1-subsection-3-card-6-description'],
          },
          {
            imgSrc: data.images['section-1-subsection-2-value-7-icon.svg'],
            title: data.content['section-1-subsection-3-card-7-title'],
            paragraph:
              data.content['section-1-subsection-3-card-7-description'],
          },
          {
            imgSrc: data.images['section-1-subsection-2-value-8-icon.svg'],
            title: data.content['section-1-subsection-3-card-8-title'],
            paragraph:
              data.content['section-1-subsection-3-card-8-description'],
          },
          {
            imgSrc: data.images['section-1-subsection-2-value-9-icon.svg'],
            title: data.content['section-1-subsection-3-card-9-title'],
            paragraph:
              data.content['section-1-subsection-3-card-9-description'],
          },
          {
            imgSrc: data.images['section-1-subsection-2-value-10-icon.svg'],
            title: data.content['section-1-subsection-3-card-10-title'],
            paragraph:
              data.content['section-1-subsection-3-card-10-description'],
          },
        ];
        this.servicesCards = [
          {
            title: data.content['section-2-card-1-title'],
            paragraph: data.content['section-2-card-1-description'],
            listContent: data.content['section-2-card-1-list'].split('\n'),
          },
          {
            title: data.content['section-2-card-2-title'],
            paragraph: data.content['section-2-card-2-description'],
            listContent: data.content['section-2-card-2-list'].split('\n'),
          },
          {
            title: data.content['section-2-card-3-title'],
            paragraph: data.content['section-2-card-3-description'],
            listContent: data.content['section-2-card-3-list'].split('\n'),
          },
          {
            title: data.content['section-2-card-4-title'],
            paragraph: data.content['section-2-card-4-description'],
            listContent: data.content['section-2-card-4-list'].split('\n'),
          },

          {
            title: data.content['section-2-card-5-title'],
            paragraph: data.content['section-2-card-5-description'],
            listContent: data.content['section-2-card-5-list'].split('\n'),
          },
          {
            title: data.content['section-2-card-6-title'],
            paragraph: data.content['section-2-card-6-description'],
            listContent: data.content['section-2-card-6-list'].split('\n'),
          },
          {
            title: data.content['section-2-card-7-title'],
            paragraph: data.content['section-2-card-7-description'],
            listContent: data.content['section-2-card-7-list'].split('\n'),
          },
        ];
        this.lifeSciencesCards = [
          {
            title: data.content['section-3-subsection-1-card-1-value'],
            subtitle:
              data.content['section-3-subsection-1-card-1-description'],
          },
          {
            title: data.content['section-3-subsection-1-card-2-value'],
            subtitle:
              data.content['section-3-subsection-1-card-2-description'],
          },
          {
            title: data.content['section-3-subsection-1-card-3-value'],
            subtitle:
              data.content['section-3-subsection-1-card-3-description'],
          },
          {
            title: data.content['section-3-subsection-1-card-4-value'],
            subtitle:
              data.content['section-3-subsection-1-card-4-description'],
          },
        ]
        this.lifeScienceCustomers.title = data.content["section-3-subsection-2-title"].replace(/<altColor>/g, '<span class="altColor">').replace(/<\/altColor>/g, '<\/span>')
        this.lifeScienceCustomers.cards = [
          {
            title: data.content['section-3-subsection-2-card-1-value'],
            subtitle:
              data.content['section-3-subsection-2-card-1-description'],
          },
          {
            title: data.content['section-3-subsection-2-card-2-value'],
            subtitle:
              data.content['section-3-subsection-2-card-2-description'],
          },
          {
            title: data.content['section-3-subsection-2-card-3-value'],
            subtitle:
              data.content['section-3-subsection-2-card-3-description'],
          },
        ];
        this.expertiseCards = [
        {
          title: data.content['section-4-subsection-1-card-1-title'],
          listContent:
            data.content['section-4-subsection-1-card-1-items'].split(
              '\n'
              ),
          },
          {
            title: data.content['section-4-subsection-1-card-2-title'],
            listContent:
              data.content['section-4-subsection-1-card-2-items'].split(
                '\n'
              ),
          },
          {
            title: data.content['section-4-subsection-1-card-3-title'],
            listContent:
              data.content['section-4-subsection-1-card-3-items'].split(
                '\n'
              ),
          },
          {
            title: data.content['section-4-subsection-1-card-4-title'],
            listContent:
              data.content['section-4-subsection-1-card-4-items'].split(
                '\n'
              ),
          },
          {
            title: data.content['section-4-subsection-1-card-5-title'],
            listContent:
              data.content['section-4-subsection-1-card-5-items'].split(
                '\n'
              ),
          },
          {
            title: data.content['section-4-subsection-1-card-6-title'],
            listContent:
              data.content['section-4-subsection-1-card-6-items'].split(
                '\n'
              ),
          },
        ];
        this.partnerships.title = data.content["section-5-title"].replace(/<altColor>/g, '<span class="altColor">').replace(/<\/altColor>/g, '<\/span>');
        this.partnerships.items = [
          {
            imgSrc: data.images['section-5-subsection-1-icon.svg'],
            engagements: data.content['section-5-subsection-1-card-1-value'],
            customers: data.content['section-5-subsection-1-card-3-value'],
            consultants: data.content['section-5-subsection-1-card-2-value'],
          },
          {
            imgSrc: data.images['section-5-subsection-2-icon.svg'],
            engagements: data.content['section-5-subsection-2-card-1-value'],
            customers: data.content['section-5-subsection-2-card-3-value'],
            consultants: data.content['section-5-subsection-2-card-2-value'],
          },
          { 
            imgSrc: data.images['section-5-subsection-3-icon.svg'],
            engagements: data.content['section-5-subsection-3-card-1-value'],
            customers: data.content['section-5-subsection-3-card-3-value'],
            consultants: data.content['section-5-subsection-3-card-2-value'],
          },
          {
            imgSrc: data.images['section-5-subsection-4-icon.svg'],
            engagements: data.content['section-5-subsection-4-card-1-value'],
            customers: data.content['section-5-subsection-4-card-3-value'],
            consultants: data.content['section-5-subsection-4-card-2-value'],
          },
          {
            imgSrc: data.images['section-5-subsection-5-icon.svg'],
            engagements: data.content['section-5-subsection-5-card-1-value'],
            customers: data.content['section-5-subsection-5-card-3-value'],
            consultants: data.content['section-5-subsection-5-card-2-value'],
          },
          {
            imgSrc: data.images['section-5-subsection-6-icon.svg'],
            engagements: data.content['section-5-subsection-6-card-1-value'],
            customers: data.content['section-5-subsection-6-card-3-value'],
            consultants: data.content['section-5-subsection-6-card-2-value'],
          },
        ] 
        this.recognition.title = data.content["section-6-title"].replace(/<altColor>/g, '<span class="altColor">').replace(/<\/altColor>/g, '<\/span>');
        this.clientLogos = [
          [data.images['section-4-subsection-2-logo-1.png'], data.images['section-4-subsection-2-logo-2.png'], data.images['section-4-subsection-2-logo-3.png'], data.images['section-4-subsection-2-logo-4.png'], data.images['section-4-subsection-2-logo-5.png']],
          [data.images['section-4-subsection-2-logo-6.png'], data.images['section-4-subsection-2-logo-7.png'], data.images['section-4-subsection-2-logo-8.png'], data.images['section-4-subsection-2-logo-9.png'], data.images['section-4-subsection-2-logo-10.png']],
          [data.images['section-4-subsection-2-logo-11.png'], data.images['section-4-subsection-2-logo-12.png'], data.images['section-4-subsection-2-logo-13.png'], data.images['section-4-subsection-2-logo-14.png'], data.images['section-4-subsection-2-logo-15.png']],
          [data.images['section-4-subsection-2-logo-16.png'], data.images['section-4-subsection-2-logo-17.png'], data.images['section-4-subsection-2-logo-18.png'], data.images['section-4-subsection-2-logo-19.png'], data.images['section-4-subsection-2-logo-20.png']],
          [data.images['section-4-subsection-2-logo-21.png'], data.images['section-4-subsection-2-logo-22.png'], data.images['section-4-subsection-2-logo-23.png'], 'data:,', 'data:,'],
        ]
      } catch (err) {
        this.isError = true;
        this.errorMessage = err.message;
      } finally {
        this.isLoading = false;
      }
    },
  },

  async beforeRouteUpdate(to, from, next) {
    await this.getData();
    next();
  },

  async created() {
    await this.getData();
  },

  components: {
    Alert,
    BottomNavigator,
    Card,
    CardGrid,
    GuidingValueCard,
    Loading,
    ImageGrid,
    Navigation,
    PageTitle,
    Signature,
    PartnershipInfo,
    SingleCard,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";
.theme-container{
  :deep(.altColor){
    color: $alt-font-color-1;
  }
}
.descriptionCards{
  margin: 1em 0px;
  .descriptionCard{  
      @include responsive(tablet){
        flex: 4 0 100%;
      }
    :deep(.card) {
      padding: 15px;
        border-radius: 0px;
        box-shadow: none;
      .cardHeader{
        color: #34373f;
        letter-spacing: .2rem;
      }
      .subtitleBold{
          font-size: 14px;
          @include color(takeda-dark-grey);
          font-weight: 600;
      }
      .contentText{
          font-size: 14px;
          @include color(slalom-dark-gray);
      }
      .grid-content{
        border-left: 5px solid $border-color-1;
      }
    }
  }
}
.atAGlanceCards{
  .atAGlanceCard{
      @include responsive(tablet){
        flex: 0 3 50%;
      }
    :deep(.card) {
      margin: 15px;
      padding: 15px;
      min-width:203px;
      .cardHeader{
      }
      .subtitle{
        font-size:42px;
        font-weight: 600;
      }
      .contentText{
        font-size: 14px;
        @include color(slalom-dark-gray);
      }
      .grid-content{
        border-left: 5px solid $border-color-2;
      }
    }
  }
}
.guidingValuesCards{
    @include responsive(phone){
      padding:3em;
    }
  .guidingValuesCard{
    margin: 15px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
    @include responsive(phone){
      flex: 1 0 100%
    }
    @include responsive(tablet){
    }
    @include bg-color(takeda-light-grey);
    :deep(.card)  {
      border-radius: 0px;
      box-shadow: none;
      .cardHeader{
      }
      .img-wrapper{
        margin-bottom:1em;
      }
      .subtitleBold{
        font-size:1.25em;
        line-height:1;
        height: 50px;
      }
      .contentText{
      }
      .grid-cell{
        width: 195px;
        @include responsive(phone){
          width:auto;
        }
      }
      .grid-content{
        padding-left:0px;
        padding-right:0px;
        margin-bottom:1.5em;
      }
    }
  }
}
.servicesCards{
    @include responsive(phone){
      padding:3em;
    }
  .servicesCard{
    margin: 15px;
    padding: 20px;
    border-radius: 10px;
    @include bg-color(takeda-dark-sorairo-blue);
    @include responsive(phone){
      flex: 1 0 100%
    }
    @include responsive(tablet){
    }
    :deep(.card)  {
      border-radius: 0px;
      box-shadow: none;
      .cardHeader{
      }
      .img-wrapper{
        margin-bottom:1em;
      }
      .subtitleBold{
        font-size:1.25em;
        line-height:1;
        height: 50px;
      }
      .contentText{
        font-size: 14px;
        font-weight:300;
      }
      .list-wrapper{
        font-size: 14px;
        font-weight:300;
      }
      .grid-cell{
        width: 195px;
        @include responsive(phone){
          width:auto;
        }
      }
      .grid-content{
        padding-left:0px;
        padding-right:0px;
        margin-bottom:1.5em;
      }
    }
  }
}
.lifeSciencesCards{
  .lifeSciencesCard{
      @include responsive(tablet){
        flex: 0 3 50%;
      }
    :deep(.card) {
      margin: 15px;
      padding: 15px;
      min-width:203px;
      background-color:$card-backgroundColor-1;
      .cardHeader{
      }
      .subtitle{
        font-size:42px;
        font-weight: 600;
      }
      .contentText{
        font-size: 14px;
        @include color(slalom-dark-gray);
      }
      .grid-content{
        border-left: 5px solid $border-color-2;
      }
    }
  }
}
.lifeScienceCustomersCards{
  .lifeScienceCustomersCard{
      @include responsive(tablet){
        flex: 0 3 50%;
      }
    :deep(.card) {
      margin: 15px;
      padding: 15px;
      min-width:203px;
      background-color:$card-backgroundColor-1;
      .cardHeader{
      }
      .subtitle{
        font-size:42px;
        font-weight: 600;
      }
      .contentText{
        font-size: 14px;
        @include color(slalom-dark-gray);
      }
      .grid-content{
        border-left: 5px solid $border-color-2;
      }
    }
  }
}
.expertiseCards{
    @include responsive(phone){
      padding:3em;
    }
  .expertiseCard{
    margin: 15px;
    padding: 20px 30px;
    border-radius: 10px;
    @include bg-color(takeda-light-grey);
    @include responsive(phone){
      flex: 1 0 100%
    }
    @include responsive(tablet){
    }
    :deep(.card)  {
      border-radius: 0px;
      box-shadow: none;
      .cardHeader{
      }
      .img-wrapper{
        margin-bottom:1em;
      }
      .subtitleBold{
        font-size:1.25em;
        line-height:1;
        height: 50px;
      }
      .contentText{
        font-size: 14px;
        font-weight:300;
      }
      .list-wrapper{
        font-size: 14px;
        font-weight:300;
      }
      .grid-cell{
        width: 250px;
        @include responsive(phone){
          width:auto;
        }
      }
      .grid-content{
        padding-left:0px;
        padding-right:0px;
        margin-bottom:1.5em;
      }
    }
  }
}
.filler {
  margin: 25px 0;
}

.life-science-customers {
  display: flex;
  justify-content: left;
}

.video-img {
  margin: 25px 0 100px 0;
}

.theme-container {
  @include relative;
  @include margin(0 0 10 0);
  :deep(.page-title)  {
    .title-container {
      background-color: $primary-color-6;
    }
  }
}

.sectionContainer {
  @include bg-color(takeda-white);
  @include padding(12 12 0 12);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.sectionContainerTitle {
  @include bg-color(takeda-white);
  @include padding(12 10 0 10);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.sectionContainerGuiding {
  @include bg-color(takeda-white);
  @include padding(12 10 12 10);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.recognition-flex {
  @include padding(12);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
  :deep(.flex-3)  {
    flex-basis: 20%;
  }
}
.grey-container {
  @include bg-color(takeda-light-grey);
  @include padding(12);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.sorairo-blue-container {
  @include bg-color(takeda-sorairo-blue);
  @include padding(12);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.sorairo-dark-blue-container {
  @include bg-color(takeda-dark-sorairo-blue);
  @include padding(6 12 6 12);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
  :deep(.main-content-container)  {
    @include bg-color(no-color);
    margin-bottom: 0;
  }
  :deep(.signature-dark)  {
    @include color(takeda-white);
  }
  :deep(.signature-light)  {
    @include color(takeda-white);
  }
}

.main-title {
  color: $font-color-1;
  font-size: 48px;
  margin-bottom: 12px;
  @include responsive(phone) {
    font-size: 22px;
  }
}

.main-title-white {
  @include color(takeda-white);
  font-size: 48px;
  margin-bottom: 12px;
  @include responsive(phone) {
    font-size: 22px;
  }
}

.blue-subtitle-title {
  @include color(takeda-sorairo-blue);
  font-size: 32px;
  margin-bottom: 12px;
  @include responsive(phone) {
    font-size: 18px;
  }
}

.white-subtitle-title {
  @include color(takeda-white);
  font-size: 28px;
  margin-bottom: 24px;
  margin-top: 24px;
  @include responsive(phone) {
    font-size: 18px;
  }
}

.subtitle {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 0px;
}

.blue-subtitle {
  @include color(takeda-sorairo-blue);
  @include margin(2 0 2 0);
  font-size: 24px;
  @include responsive(phone) {
    font-size: 18px;
  }
}

.feedback-contact-text {
  @include color(takeda-sorairo-blue);
}

.decorated-white-text {
  @include color(white);
 // text-decoration: underline;
  margin-bottom: 0;
  margin-top: 0;
}

.pad-12 {
  @include padding(1 0 1 0);
}

.client-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.client-image-container img {
  max-width: 100%;
}

.partner-award-wrapper {
  margin-top: 50px;
  display: flex;
}
.partner-award-column {
  flex: 50%;
}

.partner-award-column p {
  margin-top: 0;
  margin-bottom: 10px;
}

.recognition-wrapper {
  @include padding(4);
  display: flex;
  margin-bottom: 30px;
  @include responsive(phone) {
    @include padding(0);
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.recognition-column {
  flex: 33.3%;
}

.recognition-img {
  max-width:100%;
  margin: auto;
  display: block;
  margin-bottom: 25px;
  @include responsive(phone) {
    max-width:90%;
  }
}


.card-container {
  :deep(.rectangleCard-container)  {
    @include bg-color(takeda-dark-sorairo-blue);
  }
  :deep(h1)  {
    @include color(takeda-white);
    font-weight: 500;
  }
  :deep(p)  {
    @include color(takeda-white);
    font-weight: 300;
  }
  @include padding(8 8 8 8);
  @include responsive(phone) {
    @include padding(1 1 1 0);
  }
  @include responsive(tablet) {
    @include padding(4 4 4 4);
  }
}
</style>
